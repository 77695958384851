import {
	HttpClient,
	provideHttpClient,
	withFetch,
	withInterceptors,
} from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideRouter } from "@angular/router";
import { routes } from "@sportyano/app-routes";
import { generalInterceptor } from "@sportyano/core/interceptors/general.interceptor";
import { ConfirmationService, MessageService } from "primeng/api";
import { environment } from "src/environments/environment";
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import { provideAuth, getAuth } from "@angular/fire/auth";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { DatePipe } from "@angular/common";
import { NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER } from "ngx-google-analytics";
import { provideLottieOptions } from "ngx-lottie";

import {
	provideClientHydration,
	// withIncrementalHydration,
  } from '@angular/platform-browser';
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
export const AppConfig: ApplicationConfig = {
	providers: [
		ConfirmationService,
		DatePipe,
		provideClientHydration(),
		provideRouter(routes),
		provideHttpClient(withFetch(), withInterceptors([generalInterceptor])),
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideAuth(() => getAuth()),
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.recaptcha_site_key,
		},
		MessageService,
		importProvidersFrom(
			BrowserAnimationsModule,
			RecaptchaV3Module,
			LoadingBarModule,
			LoadingBarHttpClientModule,
			TranslateModule.forRoot({
				loader: {
					provide: TranslateLoader,
					useFactory: HttpLoaderFactory,
					deps: [HttpClient],
				},
			}),
			NgxSpinnerModule.forRoot({ type: "cube-transition" }),
			LoadingBarModule,
			LoadingBarHttpClientModule
		),
		provideLottieOptions({
			player: () => import("lottie-web"),
		}),
		NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER,
		{
			provide: 'googleAnalyticsTrackingId', // Provide the tracking ID separately
			useValue: environment.googleAnalyticsTrackingId,
		},
	],
};
